import Vue from "vue";
import Vuex from 'vuex'

import VueSilentbox from "vue-silentbox";
import App from "./App.vue";
import router from "./router";
import jQuery from "jquery";
import axios from "axios";
import VModal from "vue-js-modal/dist/index.nocss.js";
import "vue-js-modal/dist/styles.css";
import VueAwesomeSwiper from 'vue-awesome-swiper';

import "./assets/css/bootstrap.css";
import "./assets/css/sectur.css";
import "./assets/css/swiperold.css";
// import 'swiper/swiper-bundle.css';






Vue.use(VueAwesomeSwiper)

Vue.use(VueSilentbox);

Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    wbuscar: "",
    language: window.localStorage.getItem("language") !== null ? window.localStorage.getItem("language") : "es",
  },
  mutations: {
    change(state, language) {
      state.language = language;
      localStorage.setItem("language", language);
      console.log(this.language);
    },
    buscar(state, wbuscar) {
      state.wbuscar = wbuscar;
    },
  },
  getters: {
    language: state => state.language,
    wbuscar: state => state.wbuscar,
  },
  // actions: {
  //   init(store) {
  //     store.actions.setToken(store, JSON.parse(localStorage.getItem('token') || ''))
  //   },

  //   setToken(store, value) {
  //     store.commit('change', value)
  //   }
  // }
})

Vue.use(VModal, {
  dialog: true,
  dynamicDefaults: {
    draggable: true,
  },
});

axios.defaults.baseURL = "https://egobierno2.aguascalientes.gob.mx/moviles/vivaags/api/";
//axios.defaults.baseURL ="https://desarrolloweb.aguascalientes.gob.mx/moviles/secture/api/";
axios.defaults.withCredentials = false;

Vue.config.productionTip = false;



new Vue({
  data: () => ({
    idsc: null,
    tsc: null,
  }),
  store: store,
  router,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");