import Vue from "vue";
import VueRouter from "vue-router";
// import Blog from '@/components/blog.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: "/:id?",
    name: "Home",
    props: true,
    metaInfo: {
      title: 'Viva Aguascalientes'
    },
    component: function() {
      return import(/* webpackChunkName: "Home */ "../views/Home.vue");
    },
  },
  // {
  //   path: '/nota/:id?',
  //   name: 'Blog',
  //   component: Blog,
  //   props: true
  // },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(/* webpackChunkName: "about" */ "../views/About.vue");
    },
  },
  {
    path: "/Categoria/:idc/:tc",
    name: "Categoria",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(
        /* webpackChunkName: "Categoria" */
        "../views/Categoria.vue"
      );
    },
  },
  {
    path: "/Plantilla/:idc/:idsc/:tsc",
    name: "Plantilla",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: function() {
      return import(
        /* webpackChunkName: "Plantilla" */
        "../views/Plantilla.vue"
      );
    },
  },
  {
    path: "/Atractivo/:id",
    name: "Atractivo",
    component: function() {
      return import("../views/Atractivo.vue");
    },
  },
  {
    path: "/videos/",
    name: "Videos",
    component: function() {
      return import("../views/Videos.vue");
    },
  },
  {
    path: "/Busqueda",
    name: "Busqueda",
    component: function() {
      return import("../views/Busqueda.vue");
    },
  },
  {
    path: "/Directorio",
    name: "Directorio",
    component: function() {
      return import("../views/Directorio.vue");
    },
  },
  {
    path: "/SubcategoriaDir",
    name: "SubcategoriaDir",
    component: function() {
      return import("../views/SubcategoriaDir.vue");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 };
  },
});

export default router;
