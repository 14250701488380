<template>
  <footer class="w-100">
    <div class="container">
      <div class="row mt-5 align-middle">
        <div class="col-md-6">
          <img src="../assets/img/logo_veda.png" alt id="logo" class="img-fluid" />
        </div>
        <!--<div class="col-md-2 py-3">-->
          <!-- <img src="../assets/img/contigo.png" alt /> -->
          <!-- <ul>
            <li>
              <a href="#">Transparencia</a>
            </li>
            <li>
              <a href="#">Aviso de privacidad</a>
            </li>
            <li>
              <a href="#">Directorio Turístico</a>
            </li>
          </ul> -->
        <!--</div>-->
        <div class="col-md-6">
          <div class="contenedor-info">
            <p>SECRETARÍA DE TURISMO</p>
            <p>
              Paseo de la Feria 103 Col. San Marcos
              <br />Aguascalientes, Ags. C.P. 20070
              <br />Tel: 449 910 2088, 449 915 1155 <br/>y 800 949 4949
              <br /><a href="mailto:turismo@aguascalientes.gob.mx">turismo@aguascalientes.gob.mx</a>
            </p>
          </div>
          <div class="redes-contenedor">
            <a href="https://www.facebook.com/VivaAguascalientes/" target="_blank">
              <img src="../assets/img/face.png" alt />
            </a>
            <a href="https://www.instagram.com/aguascalientes_/" target="_blank">
              <img src="../assets/img/instagram.png" alt />
            </a>
            <a href="https://twitter.com/aguascalientes" target="_blank">
              <img src="../assets/img/twitter.png" alt />
            </a>
            <!-- <a href="https://www.youtube.com/user/VivaAguascalientes1" target="_blank">
              <img src="../assets/img/youtube.png" alt />
            </a>-->
            <a href="https://www.tiktok.com/@viva.aguascalientes?" target="_blank">
              <img src="../assets/img/tiktok.png" alt />
            </a> 
          </div>
        </div>
      </div>
      <div class="row my-5 justify-content-center">
        <div class="col-md-6">
          <p class="text-center">Gobierno del Estado de Aguascalientes 2022 - 2027</p>
        </div>
        <div class="col-md-3 text-center">
          <a href="/docs/AVISOS DE PRIVACIDAD_simplificado_2023.pdf">Aviso de Privacidad Simplificado</a>
        </div>
        <div class="col-md-3 text-center">
          <a href="/docs/AVISOS DE PRIVACIDAD_integral_2023.pdf">Aviso de Privacidad Integral</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer"
};
</script>
