<template>
  <nav
    class="
      navbar navbar-expand-lg navbar-light
      bg-white
      py-3
      shadow-sm
      fixed-top
    "
  >
    <router-link to="/" class="navbar-brand font-weight-bold d-block ">
      <img src="../assets/img/logo-ags.png" alt class="logo navbarLogo" />
    </router-link>
    <button
      type="button"
      data-toggle="collapse"
      data-target="#navbarContent"
      aria-controls="navbars"
      aria-expanded="false"
      aria-label="Toggle navigation"
      class="navbar-toggler"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div id="navbarContent" class="collapse navbar-collapse">
      <ul class="navbar-nav ml-auto alignItems">
        <li class="nav-item">
          <router-link to="/" class="nav-link">
            {{ language == "es" ? "Inicio" : "Home" }}</router-link
          >
        </li>
        <li class="nav-item">
          <router-link to="/about" class="nav-link">
            {{ language == "es" ? "Quiénes somos" : "About" }}</router-link
          >
        </li>
        <li class="nav-item dropdown megamenu">
          <a
            id="megamneu"
            href
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="nav-link dropdown-toggle dropdown-chevron"
            >{{ language == "es" ? "Qué hacer" : "To do" }}
          </a>
          <div
            aria-labelledby="megamneu"
            class="dropdown-menu border-0 p-0 m-0"
          >
            <div class="container-fluid">
              <div class="row bg-white rounded-0 m-0 shadow-sm w-100">
                <div class="col-lg-7 col-xl-9">
                  <div class="row">
                    <div v-for="(rs, idrs) in resp" :key="idrs" class="col-sm">
                      <ul
                        class="megamenu-list"
                        v-if="rs.subcategorias.length > 0"
                      >
                        <li class="megamenu-list-title">
                          <a :href="
                            '/Categoria/' +                            
                            rs.id + 
                            '/' +
                            rs.nombre"
                            >
                            {{ rs.nombre }} 
                            <span
                              class="submenu-indicator submenu-indicator-up"
                            ></span>
                          </a>
                        </li>
                        <ul
                          v-if="rs.subcategorias.length > 0"
                          class="megamenu-sublist"
                        >
                          <li
                            v-for="(rsb, idxrsb) in rs.subcategorias"
                            :key="idxrsb"
                            class
                          >
                            <a
                              v-if="rsb.id != 137 && rsb.id != 138  && rsb.id != 999"
                              :href="
                                '/Plantilla/' +                                
                                rs.id +
                                '/' +
                                rsb.id +
                                '/' +
                                rsb.nombre
                              "
                              >{{ rsb.nombre }}</a
                            >
                            <a
                                v-else-if="rsb.id == 999"
                                :href="'https://vivaaguascalientes.com/docs/ACUERDO_DE_ELECCION_DE_INTEGRANTES_COMITE_PUEBLOS_MAGICOS.PDF'"
                            >{{ rsb.nombre }}</a
                            >

                            <!--<router-link v-if="rsb.id != 137 && rsb.id != 138" :to="{ name: 'Plantilla', params: { idc: rs.id, idsc: rsb.id, tsc: rsb.nombre }}">{{ rsb.nombre }}</router-link>-->
                            <a
                              v-else
                              :href="
                                '/Categoria/' +                                 
                                rsb.id + 
                                '/' + 
                                rsb.nombre
                              "
                              >{{ rsb.nombre }}</a
                            >


                            <!--<router-link :to="'/Plantilla/' + rs.id + '/' + rsb.id + '/' + rsb.nombre">{{ rsb.nombre }}</router-link>-->

                            <!--<router-link :key="fullPaths" :to="'/Plantilla/' + rs.id + '/' + rsb.id + '/' + rsb.nombre">{{ rsb.nombre }}</router-link>-->
                            <!--<a href="/Plantilla/rsb.id/rsb.id/rsb.id">{{ rsb.nombre }}</a>-->
                          </li>

                        </ul>
                      </ul>
                      <ul v-else class="megamenu-list">
                        <li class="megamenu-list-title list-col-6">
                          <a href="#">{{ rs.nombre }}</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="col-lg-5 col-xl-3 px-0 d-none d-lg-block agsvg"
                  :style="{
                    backgroundImage:
                      'url(' + require('@/assets/img/ags.png') + ')',
                  }"
                ></div>
              </div>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown megamenu">
          <a
            id="megamneu"
            href
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="nav-link dropdown-toggle dropdown-chevron"
            >{{ language == "es" ? "Rutas" : "Routes" }}</a
          >
          <div
            aria-labelledby="megamneu"
            class="dropdown-menu border-0 p-0 m-0"
          >
            <div class="w-100 bg-white rounded-0 m-0 shadow-">
              <div class="container">
                <div class="row sm w-100">
                  <div class="col-sm-6 d-flex justify-content-center">
                    <div
                      v-for="(rsprt, idxrsprt) in resprutas"
                      :key="idxrsprt"
                      aria-labelledby="navbarDropdown"
                    >
                      <img
                        :src="require('@/assets/img/vino.png')"
                        class="img-rt d-none d-sm-block"
                        alt
                      />
                      <p class="titulo-rt">{{ rsprt.nombre }}</p>
                      <ul class="lista-rt mb-5">
                        <li
                          v-for="(rsbrv, idxrsbrv) in rsprt.subcategorias"
                          :key="idxrsbrv"
                        >
                          <a
                            :href="
                              '/Plantilla/' +                              
                              rsprt.id +
                              '/' +
                              rsbrv.id +
                              '/' +
                              rsbrv.nombre
                            "
                            >{{ rsbrv.nombre }}</a
                          >
                        </li>
                        <li>
                          <a href="https://rutadelvinoaguascalientes.com.mx/"
                            >Sitio oficial Ruta del Vino</a
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- <div class="col-sm-6 d-flex justify-content-center">
                    <div aria-labelledby="navbarDropdown">
                      <img
                        :src="require('@/assets/img/religioso.png')"
                        class="img-rt d-none d-sm-block"
                        alt
                      />
                      <p class="titulo-rt">Ruta Religiosa</p>
                      <img :src="require('@/assets/img/prox.png')" class="img-rt" alt />
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- <li class="nav-item">
          <router-link to="/Categoria" class="nav-link">Eventos</router-link>
        </li>
        <li class="nav-item">
          <a href class="nav-link">Dirección</a>
        </li>
        <li class="nav-item">
          <a href class="nav-link">Transparencia</a>
        </li>-->

        <li class="nav-item dropdown megamenu">
          <a
            id="megamneu"
            href
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="nav-link dropdown-toggle dropdown-chevron"
            >{{ language == "es" ? "Recorridos" : "Tours" }}</a
          >
          <div
            aria-labelledby="megamneu"
            class="dropdown-menu border-0 p-0 m-0"
          >
            <div class="w-100 bg-white rounded-0 m-0 shadow-">
              <div class="container">
                <div class="row sm w-100">
                  <div class="col-sm-6 d-flex justify-content-center">
                    <div
                      v-for="(rsprt, idxrsprt) in resprutas"
                      :key="idxrsprt"
                      aria-labelledby="navbarDropdown"
                    >
                      <img
                        :src="require('@/assets/img/recorridos.png')"
                        class="img-rt d-none d-sm-block"
                        alt
                      />
                      <p class="titulo-rt">Recorridos</p>
                      <ul class="lista-rt mb-5">
                        <li>
                          <a
                            :href="
                              //'/vivaags/' +
                              '/Categoria' +
                              '/' +
                              '161' +
                              '/' +
                              'Recorridos'
                            "
                          >{{language == "es" ? "Tranvía" : "Trolley"}}
                          </a>
                        </li>
                        <li>
                          <a href="/docs/touroperadores2023.pdf"
                            >{{language == "es" ? "Recorridos" : "Tours"}}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <!-- <div class="col-sm-6 d-flex justify-content-center">
                    <div aria-labelledby="navbarDropdown">
                      <img
                        :src="require('@/assets/img/religioso.png')"
                        class="img-rt d-none d-sm-block"
                        alt
                      />
                      <p class="titulo-rt">Ruta Religiosa</p>
                      <img :src="require('@/assets/img/prox.png')" class="img-rt" alt />
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </li> 

        <li class="nav-item">
          <a href="https://eservicios2.aguascalientes.gob.mx/eventosportal/" class="nav-link">{{
            language == "es" ? "Eventos" : "Events"
          }}</a>
        </li>
        
          <!-- <a href="https://docs.google.com/spreadsheets/d/1emSZKoaroEkgVIlF8vVOv4zPSbUXL8Un/edit#gid=437369774" class="nav-link">{{
            language == "es" ? "Directorio RNT y RET" : "RNT and RET Directory"
          }}</a> -->
        
        <li class="nav-item dropdown megamenu">
          <a
            id="megamneu"
            href
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="nav-link dropdown-toggle dropdown-chevron"
            >{{ language == "es" ? "Directorio" : "Directory" }}</a
          >
          <div
            aria-labelledby="megamneu"
            class="dropdown-menu border-0 p-0 m-0"
          >
            <div class="w-100 bg-white rounded-0 m-0 shadow-">
              <div class="container">
                <div class="row sm w-100">
                  <div class="col-sm-6 d-flex justify-content-center">
                    <div
                      v-for="(rsprt, idxrsprt) in resprutas"
                      :key="idxrsprt"
                      aria-labelledby="navbarDropdown"
                    >
                      <img
                        :src="require('@/assets/img/directorio.png')"
                        class="img-rt d-none d-sm-block"
                        alt
                      />
                      <p class="titulo-rt">Directorio</p>
                      <ul class="lista-rt mb-5">
                        <li>
                          <a
                            href="https://transparenciasectur.aguascalientes.gob.mx/RET/RET%202024.pdf"
                            class="nav-link">Registro Nacional y Estatal de Turismo</a>
                        </li>
                        <!-- <li>
                          <a href="/docs/directorioRETRNT.pdf"
                          class="nav-link">Registro Estatal de Turismo
                          </a>
                        </li> -->
                      </ul>
                    </div>
                  </div>
                  <!-- <div class="col-sm-6 d-flex justify-content-center">
                    <div aria-labelledby="navbarDropdown">
                      <img
                        :src="require('@/assets/img/religioso.png')"
                        class="img-rt d-none d-sm-block"
                        alt
                      />
                      <p class="titulo-rt">Ruta Religiosa</p>
                      <img :src="require('@/assets/img/prox.png')" class="img-rt" alt />
                    </div>
                  </div>-->
                </div>
              </div>
            </div>
          </div>
        </li> 

        <li class="nav-item">
          <a href="#blogSection" class="nav-link">
            {{ language == "es" ? "Artículos" : "Articles" }}</a
          >
        </li>

        <li class="nav-item">
          <a href="./videos" class="nav-link">
            {{ language == "es" ? "Vídeos" : "Media" }}</a
          >
        </li>

        <!--<li class="nav-item dropdown megamenu">
          <a
            id="megamneu"
            href
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            class="nav-link dropdown-toggle dropdown-chevron"
            >{{ language == "es" ? "Aviso de privacidad" : "Privacy Policy" }}</a
          >
          <div
            aria-labelledby="megamneu"
            class="dropdown-menu border-0 p-0 m-0"
          >
            <div class="w-100 bg-white rounded-0 m-0 shadow-">
              <div class="container">
                <div class="row sm w-100">
                  <div class="col-sm-6 d-flex justify-content-center">
                    <div
                      v-for="(rsprt, idxrsprt) in resprutas"
                      :key="idxrsprt"
                      aria-labelledby="navbarDropdown"
                    >
                      <img
                        :src="require('@/assets/img/privacidad.png')"
                        class="img-rt d-none d-sm-block"
                        alt
                      />
                      
                      <ul class="lista-rt mb-5">
                        <li>
                          <a
                            href="https://desarrolloweb.aguascalientes.gob.mx/vivaags/docs/AVISOS DE PRIVACIDAD_simplificado_2023.pdf"
                          >{{language == "es" ? "Aviso de privacidad simplificado" : "Simplified Privacy Policy"}}
                          </a>
                        </li>
                        <li>
                          <a href="https://desarrolloweb.aguascalientes.gob.mx/vivaags/docs/AVISOS DE PRIVACIDAD_integral_2023.pdf"
                            >{{language == "es" ? "Aviso de privacidad integral" : "Full Privacy Policy"}}
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  
                </div>
              </div>
            </div>
          </div>
        </li> -->
           <!-- <li class="nav-item">
          <a href="https://www.hotelesenaguascalientes.com.mx/contact" class="nav-link">{{
            language == "es" ? "Hoteles" : "Hotels"
          }}</a>
        </li>

        <li class="nav-item">
          <a href="https://vivaaguascalientes.com/docs/InfoVuelos.pdf" target="_blank" class="nav-link">{{
            language == "es" ? "Vuelos" : "Flights"
          }}</a>
        </li> -->

       
        <!-- <li class="nav-item">
          <a href="/sectur/OTA" class="nav-link">OTA</a>
        </li> -->
        <!-- <li>
          <div class="redes-contenedor inverse">
            <a href="https://www.facebook.com/VivaAguascalientes/">
              <img src="../assets/img/face.png" alt />
            </a>
            <a href="https://www.instagram.com/aguascalientes_/">
              <img src="../assets/img/instagram.png" alt />
            </a>
            <a href="https://twitter.com/aguascalientes">
              <img src="../assets/img/twitter.png" alt />
            </a>
            <a href="https://www.youtube.com/user/VivaAguascalientes1">
              <img src="../assets/img/youtube.png" alt />
            </a>
            <a href="https://www.tiktok.com/@vivaaguascalientes">
              <img src="../assets/img/tiktok.png" alt />
            </a>
          </div>
        </li> -->
        <li>
          <div class="search">
            <input
              type="text"
              class="searchTerm"
              placeholder="Buscar"
              v-model="wbuscar"
              v-on:keyup.enter="buscar"
            />
            <button type="submit" class="searchButton" @click="buscar">
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M17.71 16.29L14.31 12.9C15.407 11.5025 16.0022 9.77666 16 8C16 6.41775 15.5308 4.87103 14.6518 3.55544C13.7727 2.23985 12.5233 1.21447 11.0615 0.608967C9.59966 0.00346625 7.99113 -0.15496 6.43928 0.153721C4.88743 0.462403 3.46197 1.22433 2.34315 2.34315C1.22433 3.46197 0.462403 4.88743 0.153721 6.43928C-0.15496 7.99113 0.00346625 9.59966 0.608967 11.0615C1.21447 12.5233 2.23985 13.7727 3.55544 14.6518C4.87103 15.5308 6.41775 16 8 16C9.77666 16.0022 11.5025 15.407 12.9 14.31L16.29 17.71C16.383 17.8037 16.4936 17.8781 16.6154 17.9289C16.7373 17.9797 16.868 18.0058 17 18.0058C17.132 18.0058 17.2627 17.9797 17.3846 17.9289C17.5064 17.8781 17.617 17.8037 17.71 17.71C17.8037 17.617 17.8781 17.5064 17.9289 17.3846C17.9797 17.2627 18.0058 17.132 18.0058 17C18.0058 16.868 17.9797 16.7373 17.9289 16.6154C17.8781 16.4936 17.8037 16.383 17.71 16.29V16.29ZM2 8C2 6.81332 2.3519 5.65328 3.01119 4.66658C3.67047 3.67989 4.60755 2.91085 5.7039 2.45673C6.80026 2.0026 8.00666 1.88378 9.17055 2.11529C10.3344 2.3468 11.4035 2.91825 12.2426 3.75736C13.0818 4.59648 13.6532 5.66558 13.8847 6.82946C14.1162 7.99335 13.9974 9.19975 13.5433 10.2961C13.0892 11.3925 12.3201 12.3295 11.3334 12.9888C10.3467 13.6481 9.18669 14 8 14C6.4087 14 4.88258 13.3679 3.75736 12.2426C2.63214 11.1174 2 9.5913 2 8Z"
                  fill="white"
                />
              </svg>
            </button>
          </div>
        </li>
        <li>
          <div class="redes-contenedor select">
            <select v-if="language == 'es'" @change.prevent="change">
              <option value="es" selected>Español</option>
              <option value="en">Inglés</option>
            </select>
            <select v-if="language == 'en'" @change.prevent="change">
              <option value="es">Spanish</option>
              <option value="en" selected>English</option>
            </select>
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    resp: null,
    resprutas: null,
    loading: true,
    errored: false,
    wbuscar: "",
  }),
  computed: {
    language: function () {
      return this.$store.getters.language;
    },
  },
  watch: {
    language: function (nw) {
      this.$store.commit("change", nw);

      this.loadNavBar();
    },
  },
  mounted() {
    $(document).ready(function () {
      $(".dropdown").hover(
        function () {
          $(".dropdown-menu", this)
            .not(".in .dropdown-menu")
            .stop(true, true)
            .slideDown("200");
          $(this).toggleClass("open");
        },
        function () {
          $(".dropdown-menu", this)
            .not(".in .dropdown-menu")
            .stop(true, true)
            .delay(100)
            .slideUp("400");
          $(this).toggleClass("open");
        }
      );
    });
  },
  created() {
    this.loadNavBar();
  },
  methods: {
    buscar() {
      this.$store.commit("buscar", this.wbuscar);

      if (this.wbuscar.length <= 0) return;
      const path = "/Busqueda";
      if (this.$route.path !== path) this.$router.push(path);
    },
    loadNavBar() {
      axios
        .get("/CategoriasWeb/quehacer?api-version=1.1&lang=" + this.language)
        .then((response) => {
          this.resp = response.data;
          this.resp[0].subcategorias[4] ={id:"999", "nombre": "Comité"}

          console.log("------------------NAV RESP-----------------")
          console.log(this.resp)
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });

      axios
        .get("/CategoriasWeb/rutas?api-version=1.1&lang=" + this.language)
        .then((response) => {
          this.resprutas = response.data;
        })
        .catch((error) => {
          console.log(error);
          this.errored = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    change: function (event) {
      this.$store.commit("change", event.target.value);
    },
  },
};
</script>

<style lang="scss">
.navbarLogo{
height: 60px;
width: auto;
background: white;
position: absolute;
top: 10px;
left: 10px;
padding: 5px;

@media (max-width: 600px) {
    height: 50px;
  }
}

.navbar-light .navbar-nav a.nav-link {
  color: #3E4C79;
}

.fixed-top {
  z-index: 500 !important;
}
.titulo-rt {
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  line-height: 22px;
  color: #3E4C79;
}

.lista-rt {
  list-style: none;
  padding: 0 !important;

  li {
    padding: 0;

    a {
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #4F85A1;
      transition: all ease 0.2s;
    }
    a:hover {
      text-decoration: none;
      color: #B12973;
    }
  }
}

.img-rt {
  margin-bottom: 10px;
}

.megamenu {
  position: static !important;
}

.megamenu .dropdown-menu {
  background: none;
  border: none;
  width: 100%;
}

.megamenu-list {
  list-style: none;
  padding: 0px;
  margin: 10px;

  a:hover {
    text-decoration: none;
  }
}

.megamenu-sublist {
  list-style: none;
  padding: 0px;

  a {
    font-size: 0.9rem;
    color: #4F85A1;
  }
  a:hover {
    text-decoration: none;
    color: #B12973;
  }
}

.megamenu-list-title a {
  color: #3E4C79 !important;
  font-weight: 600;
  font-size: 0.8rem !important;

  a:hover {
    text-decoration: none !important ;
  }
}

.dropdown-chevron::after {
  height: 6px;
  width: 6px;
  display: block;
  border-style: solid;
  border-width: 0 1px 1px 0;
  border-color: transparent #70798b #70798b transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: border 0.2s;
  transition: border 0.2s;
}

.agsvg {
  background-position: center;
  background-size: cover;
}

.colors {
  background: rgba(102, 16, 242, 0.5);
  background: #3E4C79;
}

.select {
  select {
    color: white;
    border: 2px solid #000;
    padding: 5px;
    border-radius: 4px;
    background: #000;
  }
}
.redes-contenedor.select select {
  border: 2px solid #3E4C79;
  background: #3E4C79;
  padding: 5px;
  border-radius: 4px;

  &:focus {
    border: lighten(#3E4C79, 20%);
  }
}
.search {
  width: 100%;
  position: relative;
  display: flex;

  @media (max-width: 600px) {
    margin-bottom: 20px;
  }
}

.searchTerm {
  width: 100%;
  border: 3px solid #3E4C79;
  border-right: none;
  padding: 5px;
  height: 36px;
  border-radius: 5px 0 0 5px;
  outline: none;
  color: gray;
  font-size: 14px;
  padding: 10px;
}

.searchTerm:focus {
  color: black;
  outline-color: red;
}

.searchButton {
  width: 40px;
  height: 36px;
  border: 1px solid #3E4C79;
  background: #3E4C79;
  text-align: center;
  color: #fff;
  border-radius: 0 5px 5px 0;
  cursor: pointer;
  font-size: 20px;
  margin-right: 20px;
  transition: all ease 0.2s;

  &:hover {
    background: lighten(#3E4C79, 20%);
  }

  @media (max-width: 600px) {
    margin-right: 0px;
  }

  svg {
    margin-top: -1px;
  }
}

/*Resize the wrap to see the search bar change!*/
.wrap {
  width: 30%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>
