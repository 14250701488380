<template>
  <div id="app">
    <NavBarDyn />
    <router-view :key="$router.fullPath" />
    <Footer />
  </div>
</template>

<script>
import NavBarDyn from "./components/NavBarDyn";
import Footer from "./components/Footer";

export default {
  components: {
    NavBarDyn,
    Footer,
  },
};
</script>
